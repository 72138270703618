export const defaultFiltersObject = () => ({
  searchValue: "",
  saleStatus: null,
  periodType: null,
  periodInclusion: null,
  dateRange: [],
  otas: [],
  shops: [],
  carClasses: []
})

export const defaultPlanObject = () => ({
  released: false,
  unreleasable: true,
  code: null,
  name: null,
  type: "regular",
  sale_start: null,
  sale_end: null,
  sale_days: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    holiday: false
  },
  post_start: null,
  post_end: null,
  closing_out_type: "shop_hours",
  closing_out_options: {
    until_hour: null,
    days_before: null
  },
  companies_shop_ids: [],
  ota_ids: []
})
